<template>
  <dialog
    ref="dialogRef"
    class="tw-modal tw-backdrop-blur-sm tw-bg-base-200/30"
  >
    <div
      class="tw-modal-box tw-w-full tw-max-w-lg tw-mx-auto tw-px-2"
      :class="[contentClass, paddingClass ? paddingClass : closable ? 'tw-py-10' : 'tw-py-6']"
    >
      <form method="dialog" v-if="closable">
        <button
          class="tw-absolute tw-right-6 tw-top-6 tw-z-50 tw-w-[14px] tw-h-[14px] tw-text-neutral"
        >
          <svgo-close class="tw-w-full tw-h-full" />
        </button>
      </form>

      <slot />
    </div>
    <form method="dialog" class="tw-modal-backdrop" v-if="maskClosable">
      <button>close</button>
    </form>
  </dialog>
</template>
<script setup lang="ts">
const dialogRef = ref<HTMLDialogElement | null>(null);
const visible = defineModel("visible");
const props = withDefaults(
  defineProps<{
    closable?: boolean;
    maskClosable?: boolean;
    contentClass?: string;
    paddingClass?: string;
  }>(),
  {
    contentClass: "tw-rounded-[40px]",
  }
);

const close = () => {
  const dialog = unref(dialogRef);
  if (dialog) {
    visible.value = false;
    dialog.close();
  }
};
const open = () => {
  const dialog = unref(dialogRef);
  if (dialog) {
    visible.value = true;
    dialog.showModal();
  }
};

onMounted(() => {
  const dialog = unref(dialogRef);
  if (dialog) {
    dialog.addEventListener("close", () => {
      visible.value = false;
    });
  }
});

defineExpose<{ open: () => void; close: () => void }>({
  close,
  open,
});
</script>
